import React, {useContext } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AuthContext from "./contexts/AuthContext";
import AdminDashboard from "./pages/admin/Dashboard";
import Dashboard from "./pages/Dashboard";
import ErrorPage from "./pages/ErrorPage";
import Login from "./pages/Login";

const userRoutes = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: "dashboard",
    element: <Dashboard />,
    errorElement: <ErrorPage />,
  },
]);

const adminRoutes = createBrowserRouter([
  {
    path: "/",
    element: <AdminDashboard />,
    errorElement: <ErrorPage />,
  },
]);

const authRoutes = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
]);

export default function Router() {
  const { user } = useContext(AuthContext);

  return (
    <React.StrictMode>
      <RouterProvider
        router={
          user !== null
            ? user.role === "SUPERADMIN"
              ? adminRoutes
              : userRoutes
            : authRoutes
        }
      />
    </React.StrictMode>
  );
}
