import axios from "axios";

const isProd = false;
const BASE_URL = isProd
  ? "https://scaleyou.vercel.app"
  : "http://localhost:4000";

const authInstance = axios.create({
  baseURL: BASE_URL,
});

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))?.token}`,
    "Content-Type": "*",
    // Add any other headers if required
  },
});

export const API = {
  login: (email, password, type) =>
    authInstance.post("/auth/login", {
      role: type,
      email: email,
      password: password,
    }),
  admin: {
    listSchools: () => instance.get("/admin/list-schools"),
    listUsers: () => instance.get("/admin/list-users"),
  },
};
