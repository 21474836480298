import { useContext, useState } from "react";
import Notification from "../components/helpers/Notification";
import AuthContext from "../contexts/AuthContext";
import { API } from "../services/API";

export default function Login() {
  const { updateUser } = useContext(AuthContext);
  const [inputEmail, setInputEmail] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [role, setRole] = useState("SUPERADMIN");

  async function login() {
    try {
      const data = await API.login(inputEmail, inputPassword, role);
      if (data.data) {
        updateUser({
          email: data.data.user.email,
          role: role,
          token: data.data.jwt,
        });
      }
    } catch (error) {
      Notification("Invalid Credentials");
    }
  }

  const onRoleChange = (event) => {
    setRole(event.target.value);
  };

  return (
    <div className="container vh-100">
      <div className="d-flex flex-column m-auto align-items-center justify-content-center vh-100">
        <div className="d-flex">
          <input
            placeholder="Email"
            value={inputEmail}
            onChange={(evnt) => setInputEmail(evnt.target.value)}
          />
          <input
            placeholder="Password"
            value={inputPassword}
            onChange={(evnt) => setInputPassword(evnt.target.value)}
          />
        </div>
        <div className="d-flex align-items-around m-2">
          <label className="p-2">
            <input
              type="radio"
              value="SUPERADMIN"
              checked={role === "SUPERADMIN"}
              onChange={onRoleChange}
            />
            Superadmin
          </label>
          <label className="p-2">
            <input
              type="radio"
              value="SUPERVISOR"
              checked={role === "SUPERVISOR"}
              onChange={onRoleChange}
            />
            Supervisor
          </label>
          <label className="p-2">
            <input
              type="radio"
              value="STAFF"
              checked={role === "STAFF"}
              onChange={onRoleChange}
            />
            Staff
          </label>
          <label className="p-2">
            <input
              type="radio"
              value="STUDENT"
              checked={role === "STUDENT"}
              onChange={onRoleChange}
            />
            Student
          </label>
        </div>

        <button className="btn btn-success" onClick={() => login()}>
          Login
        </button>
      </div>
    </div>
  );
}
