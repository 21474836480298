import { Store } from "react-notifications-component";

export default function Notification(msg) {
  Store.addNotification({
    title: msg,
    // message: "error",
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
      showIcon: true,
      pauseOnHover: true,
    },
  });
}
