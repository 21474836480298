import React, { createContext, useState, useEffect } from "react";

// Create a context
const AuthContext = createContext();

// Create a custom provider
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  const updateUser = (val) => {
    if (val) localStorage.setItem("user", JSON.stringify(val));
    else localStorage.removeItem("user");
    setUser(val);
  };

  return (
    <AuthContext.Provider value={{ user, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
