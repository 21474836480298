import { useContext, useEffect, useRef, useState } from "react";
import Notification from "../../components/helpers/Notification";
import AuthContext from "../../contexts/AuthContext";
import { API } from "../../services/API";

export default function AdminDashboard() {
  const { user, updateUser } = useContext(AuthContext);
  const [schools, setSchools] = useState([]);
  const [users, setUsers] = useState([]);
  const isInitialLoading = useRef(true); 
//   var isInitialLoading = false;

  async function getSchools() {
    try {
      const schoolsData = await API.admin.listSchools();
      setSchools(schoolsData.data.result);
    } catch (error) {
      Notification("Failed to fetch schools");
    }
  }

  async function getUsers() {
    try {
      const usersData = await API.admin.listUsers();
      setUsers(usersData.data.result);
    } catch (error) {
      Notification("Failed to fetch schools");
    }
  }

  useEffect(() => {
    if (isInitialLoading) {
      getSchools();
      getUsers();
    }
    isInitialLoading.current = false;
  }, []);

  return (
    <div>
      Welcome Admin, {user?.email}
      <div className="d-flex">
        <div className="m-3">
          <h5>Schools List</h5>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Name</th>
                <th scope="col">Created At</th>
              </tr>
            </thead>
            <tbody>
              {schools.map((school, index) => {
                return (
                  <tr key={index}>
                    <td>{school._id}</td>
                    <td>{school.name}</td>
                    <td>{school.created_at}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="m-3">
          <h5>Users List</h5>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">School</th>
                <th scope="col">Role</th>
                <th scope="col">Password</th>
                <th scope="col">Signup Key</th>
              </tr>
            </thead>
            <tbody>
              {users.map((school, index) => {
                return (
                  <tr key={index}>
                    <td>{index}</td>
                    <td>{school.name ?? "--"}</td>
                    <td>{school.email}</td>
                    <td>{school.school_id}</td>
                    <td>{school.role}</td>
                    <td>{school.password ?? "--"}</td>
                    <td>{school.signup_key}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <button
        className="btn btn-danger position-absolute top-0 end-0 m-2"
        onClick={() => {
          updateUser(null);
        }}
      >
        Logout
      </button>
    </div>
  );
}
