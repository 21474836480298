import { useContext } from "react";
import AuthContext from "../contexts/AuthContext";

export default function Dashboard() {
  const { user, updateUser } = useContext(AuthContext);

  return (
    <div>
      Welcome, {user?.email}
      <button
        onClick={() => {
          updateUser(null);
        }}
      >
        Logout
      </button>
    </div>
  );
}
